import React from "react";
import mapImage from "../../images/mapImage.png";
import styles from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <div className={styles.mapContainer}>
      <h2 className={styles.title}>Represent UNS</h2>
      <img src={mapImage} alt="#" className={styles.mapImag} />
      <div className={styles.buttonContainer}>
        <button
          onClick={() =>
            window.open("https://t6vbhfn3e4x.typeform.com/to/NWE9yb2N")
          }
          className={styles.button}
        >
          Apply Now
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
